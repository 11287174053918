const isProduction = process.env.REACT_APP_ENV === "production";
const isTest = process.env.REACT_APP_ENV === "test";

const frontendHostname = window.location.hostname as keyof typeof apiMappings;

const apiMappings: Record<string, string> = {
  "getmanagd.com": "https://api.getmanagd.com",
  "www.getmanagd.com": "https://api.getmanagd.com",
  "managd.ae": "https://api.managd.ae",
  "www.managd.ae": "https://api.managd.ae",
  "staging.getmanagd.com": "https://staging-api.getmanagd.com",
  "staging.managd.ae": "https://staging-api.managd.ae",
};

// Dynamically get local API URL
const localApiUrl = `http://${window.location.hostname}:8585`;

// Safe lookup with fallback
export const API_URL =
  apiMappings[frontendHostname] ||
  (isProduction
    ? "https://api.getmanagd.com"
    : isTest
    ? "https://staging-api.getmanagd.com"
    : localApiUrl);

console.log("Using API URL:", API_URL);

export const SPACES_ENDPOINT = isProduction
  ? "https://managd-production.fra1.digitaloceanspaces.com/"
  : "https://managd-staging.fra1.digitaloceanspaces.com/";
